<template>
  <footer>
    <span class="copyright"> © Pomelo Records, 2025. all rights reserved.</span>
    <span
      ><router-link to="/imprint">imprint</router-link>
      <router-link to="/privacy">privacy</router-link>
      <router-link to="/contact">contact</router-link>
      <router-link to="/links">links</router-link>
    </span>
  </footer>
</template>

<script>
export default {
  name: "FooterNavigation",
};
</script>

<style scoped>
footer {
  display: none;
}
@media screen and (min-width: 500px) {
  footer {
    display: flex;
    justify-content: space-around;
    background-color: rgba(36, 36, 36, 0.9);
    opacity: 1;
    /* padding: 3rem; */
    width: 100%;
    height: 3rem;
  }
  footer span {
    color: #999;
    opacity: 75%;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: left;
    margin: auto;
    width: 70%;
  }
  .copyright {
    display: none;
  }
  footer span:nth-child(1) {
    margin-right: 30px;
    /* margin-left: 50px; */
  }
  footer span:last-child {
    margin-right: 100px;
  }
  footer span a {
    color: #999;
    opacity: 75%;
    font-size: 0.8rem;
    font-weight: 400;
    text-decoration: none;
    margin-right: 10%;
  }
  footer span a:hover {
    color: #666;
  }
}
@media screen and (min-width: 800px) {
  .copyright {
    display: block;
    margin-left: 2.5rem;
  }
}
</style>
